import Api from "@/services/Index"


const changeProcessEnv = ()=>{
    Api.defaults.baseURL=process.env.VUE_APP_ENDPOINT_STUDENT
}

const getAllWithDraw = (config)=>{
    changeProcessEnv()
    return Api.get("/withdraws",config)
}

const getStudentCourse = ()=> {
    changeProcessEnv()
    return Api.get("/withdraws/student-form")
}

const show = (id)=>{
    changeProcessEnv()
    return Api.get("/withdraws/"+id)
}

const store = (data)=>{
    changeProcessEnv()
    return Api.post("/withdraws",data)
}

const getWithDrawRequestFormPreview = (data)=>{
    changeProcessEnv()
    return Api.post("/withdraws/course-withdraw-request-form-preview",data)
}

export default {
    getAllWithDraw,getStudentCourse,show,store,getWithDrawRequestFormPreview
}

